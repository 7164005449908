import { FORM_ERROR } from 'final-form';

const processArrayError = (item) => {
  if (typeof item === 'string') {
    return item;
  }

  if (typeof item[0] === 'string') {
    return item.join(' ');
  }

  return item.map((current) =>
    Object.keys(current).reduce(
      (pv, next) => ({
        ...pv,
        [next]: processArrayError(current[next]),
      }),
      {}
    )
  );
};

const createFormError = ({ meta, payload, ...props }) => {
  const status = meta ? meta.status : props.status;

  if (status === -1 || status === 0) {
    return {
      [FORM_ERROR]: 'Connection timeout. Please, check your Internet connection.',
    };
  }

  if (status === 404) {
    return {
      [FORM_ERROR]: payload?.detail || 'Not found.',
    };
  }

  if (status === 403) {
    return {
      [FORM_ERROR]: payload?.detail || 'Forbidden.',
    };
  }

  if ((status === 400 || status === 401) && typeof payload === 'object') {
    return Object.keys(payload).reduce((pv, next) => {
      if (['nonFieldErrors', 'detail'].indexOf(next) >= 0) {
        return {
          ...pv,
          [FORM_ERROR]: processArrayError(payload[next]),
        };
      }

      return {
        ...pv,
        [next]: processArrayError(payload[next]),
      };
    }, {});
  }

  if (status >= 400) {
    return {
      [FORM_ERROR]: 'Internal server error. Please, try again later.',
    };
  }

  return undefined;
};

export const errorToString = (e) => {
  const error = createFormError(e);

  if (error) {
    return Object.values(error).join(' ');
  }

  return 'We encountered an uknown error.';
};

export default createFormError;
