import React from 'react';
import { css } from '@emotion/core';
import arrayMutators from 'final-form-arrays';
import createDecorator from 'final-form-focus';
import get from 'lodash/get';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { Field, Form } from 'react-final-form';
import { FieldArray } from 'react-final-form-arrays';
import { useDispatch } from 'react-redux';
import { Box, Flex, Heading, Text } from 'rebass';

import Button from 'components/Button';
import Input from 'components/Input';
import Select from 'components/Select';
import TippedLabel from 'components/TippedLabel';
import WithModal from 'components/WithModal';
import useAlert from 'hooks/useAlert';
import { QUESTION_TYPE, QUESTION_TYPE_NAME, QUIZ_STATUS, QUIZ_TYPE } from 'redux/enums';
import * as coursesFeatures from 'redux/features/course';
import { errorToString } from 'utils/createFormError';
import { checkRequired } from 'utils/validation';

import DroppableAnswers from '../DroppableAnswers';

const focusOnErrors = createDecorator();

const DisplayQuiz = ({ course, chapter, lesson, quizzes, quizQuestions, type, getQuizzes }) => {
  const alert = useAlert();
  const dispatch = useDispatch();

  const quiz = React.useMemo(() => quizzes.find((quiz) => quiz.lesson === lesson.id && quiz.quizType === type), [
    quizzes,
    lesson,
    type,
  ]);

  const [questions, setQuestions] = React.useState(() => (quiz ? quizQuestions.filter((q) => q.quiz === quiz.id) : []));

  React.useEffect(() => {
    if (quiz) {
      const newQuestions = quizQuestions.filter((q) => q.quiz === quiz.id);

      setQuestions(newQuestions);

      dispatch(
        coursesFeatures.getAnswers({
          params: {
            quiz: quiz.id,
            limit: 100,
          },
        })
      ).then((result) => {
        if (result.error) {
          return alert.onFailure(errorToString(result));
        }

        setQuestions(
          newQuestions.map((question) => ({
            ...question,
            answers: result.payload.results.filter((answer) => answer.quizQuestion === question.id),
          }))
        );
      });
    }
  }, [quiz]);

  const quizName = type === QUIZ_TYPE.QUIZ ? 'Graded Quiz' : 'Reflection';

  const refetch = (result) => {
    if (result.error) {
      return alert.onFailure(errorToString(result));
    }

    return getQuizzes();
  };

  const createQuiz = () =>
    dispatch(
      coursesFeatures.postQuiz({
        body: {
          lesson: lesson.id,
          status: QUIZ_STATUS.DRAFT,
          quizType: type,
        },
      })
    ).then(refetch);

  return (
    <Form
      onSubmit={async (values) => {
        const questionsWithFixedOrder = values.questions.map((question, index) => ({
          ...question,
          answers: question.answers.map((answer, answerIndex) => ({
            ...answer,
            order: answerIndex,
          })),
          order: index,
        }));

        const newQuestionsIds = questionsWithFixedOrder.map(({ id }) => id);
        const newAnswersIds = questionsWithFixedOrder
          .map(({ answers }) => answers)
          .flat()
          .map(({ id }) => id);

        const deletedQuestions = questions.filter(({ id }) => !newQuestionsIds.includes(id)).map(({ id }) => id);
        const deletedAnswers = questions
          .filter(({ id }) => !deletedQuestions.includes(id))
          .map(({ answers }) => answers)
          .flat()
          .map(({ id }) => id)
          .filter((id) => !newAnswersIds.includes(id));

        const newQuestions = questionsWithFixedOrder.filter(({ id }) => typeof id === 'string');
        const newAnswers = questionsWithFixedOrder
          .map(({ answers }) => answers)
          .flat()
          .filter(({ id }) => typeof id === 'string');

        const result = await Promise.all([
          ...newQuestions.map(({ id, order, ...item }) =>
            dispatch(
              coursesFeatures.postQuestions({
                body: {
                  ...item,
                  status: values.status,
                  order,
                },
              })
            )
          ),
          ...deletedQuestions.map((id) =>
            dispatch(
              coursesFeatures.deleteQuestions({
                id,
              })
            )
          ),
          ...deletedAnswers.map((id) =>
            dispatch(
              coursesFeatures.deleteAnswers({
                id,
              })
            )
          ),
        ]);

        let someErrors = result
          .filter(({ error }) => error)
          .map(({ payload }) => JSON.stringify(payload))
          .join(' ');

        if (someErrors.length) {
          return alert.onFailure(`Error has occured while creating or deleting first items: ${someErrors}`);
        }

        const actualQuestionIds = newQuestions.reduce((pv, cv, index) => {
          const payload = result[index].payload;
          return {
            ...pv,
            [cv.id]: payload.id,
          };
        }, {});

        const patchQuestions = questionsWithFixedOrder.filter(
          ({ id }) => typeof id === 'number' && !deletedQuestions.includes(id)
        );

        const patchAnswers = questionsWithFixedOrder
          .map(({ answers }) => answers)
          .flat()
          .filter(({ id }) => typeof id === 'number' && !deletedAnswers.includes(id));

        const patchResult = await Promise.all([
          ...newAnswers.map(({ id, quizQuestion, order, ...item }) =>
            dispatch(
              coursesFeatures.postAnswers({
                body: {
                  ...item,
                  order,
                  quizQuestion: typeof quizQuestion === 'string' ? actualQuestionIds[quizQuestion] : quizQuestion,
                },
              })
            )
          ),
          ...patchQuestions.map(({ question, id, exampleAnswer, order }) =>
            dispatch(
              coursesFeatures.patchQuestions({
                id,
                body: {
                  question,
                  exampleAnswer,
                  order,
                  status: values.status,
                },
              })
            )
          ),
          ...patchAnswers.map(({ id, answer, isCorrect, reason, order }) =>
            dispatch(
              coursesFeatures.patchAnswers({
                id,
                body: {
                  answer,
                  isCorrect,
                  reason,
                  order,
                },
              })
            )
          ),
        ]);

        someErrors = patchResult
          .filter(({ error }) => error)
          .map(({ payload }) => JSON.stringify(payload))
          .join(' ');

        if (someErrors.length) {
          return alert.onFailure(
            `Error has occured while posting/patching answers or patching questions items: ${someErrors}`
          );
        }

        if (quiz.status !== values.status) {
          const patchResult = await dispatch(
            coursesFeatures.patchQuiz({
              id: quiz.id,
              body: {
                status: Number(values.status),
              },
            })
          );

          if (patchResult.error) {
            return alert.onFailure(errorToString(patchResult));
          }
        }

        await getQuizzes();

        alert.onSuccess('Quiz updated successfully.');
      }}
      decorators={[focusOnErrors]}
      mutators={{
        ...arrayMutators,
      }}
      initialValues={{
        questions,
        status: quiz?.status || QUIZ_STATUS.DRAFT.toString(),
        questionType: String(type === QUIZ_TYPE.REFLECTION ? QUESTION_TYPE.GAP : QUESTION_TYPE.CHOICE),
      }}
    >
      {({ handleSubmit, form, ...meta }) => (
        <Box as="form" onSubmit={handleSubmit}>
          <Flex flexDirection="row">
            <Box width={1}>
              <Heading fontSize={3}>
                {course.name}
                <br />
                Chapter {chapter.order + 1}, Lesson {lesson.order + 1}, {quizName}
              </Heading>
            </Box>
            <Box ml={3} width={32}>
              <WithModal
                text="Are you sure you want to delete this quiz?"
                renderButtons={({ close }) => (
                  <Flex>
                    <Box width={0.5}>
                      <Button variant="outline" onClick={close}>
                        Cancel
                      </Button>
                    </Box>
                    <Box width={0.5}>
                      <Button
                        variant="primary"
                        resolve={() =>
                          dispatch(
                            coursesFeatures.deleteQuiz({
                              id: quiz.id,
                            })
                          ).then((result) => {
                            close();
                            return refetch(result);
                          })
                        }
                      >
                        Delete Quiz
                      </Button>
                    </Box>
                  </Flex>
                )}
              >
                {({ open }) => (
                  <Button variant="nulled" mt={2} onClick={open}>
                    <img
                      src={require('assets/delete.svg')}
                      css={css`
                        width: 32px;
                        height: 20px;
                      `}
                      alt="Delete question"
                    />
                  </Button>
                )}
              </WithModal>
            </Box>
          </Flex>
          <Box mt={3}>
            {quiz && (
              <Field name="status" component={Select} validate={checkRequired} label="Select Quiz Status">
                <option value={QUIZ_STATUS.DRAFT}>Draft</option>
                <option value={QUIZ_STATUS.PUBLISHED}>Published</option>
              </Field>
            )}
            {!quiz ? (
              <Box>
                <Text textAlign="center">This lesson does not have a {quizName}.</Text>
                <Button primary mt={3} resolve={createQuiz}>
                  Create draft for {quizName}
                </Button>
              </Box>
            ) : (
              <FieldArray name="questions">
                {({ fields }) => (
                  <DragDropContext
                    onDragEnd={(result) => {
                      if (!result.destination) {
                        return;
                      }

                      const { index: sourceIndex } = result.source;
                      const { index: newIndex, droppableId } = result.destination;

                      const index = parseInt(droppableId.split('drop_')[1], 10);
                      const questionsCopy = [...fields.value];

                      if (result.type === 'answers') {
                        const answersCopy = [...fields.value[index].answers];
                        const [removed] = answersCopy.splice(sourceIndex, 1);
                        answersCopy.splice(newIndex, 0, removed);

                        questionsCopy[index] = {
                          ...questionsCopy[index],
                          answers: answersCopy,
                        };
                        form.change('questions', questionsCopy);
                      } else if (result.type === 'questions') {
                        const [removed] = questionsCopy.splice(sourceIndex, 1);
                        questionsCopy.splice(newIndex, 0, removed);
                        form.change('questions', questionsCopy);
                      }
                    }}
                  >
                    <Droppable droppableId={`quiz_${quiz.id}`} type="questions">
                      {(provided) => (
                        <div ref={provided.innerRef} {...provided.droppableProps}>
                          {fields.map((name, index) => (
                            <Draggable draggableId={`question_${index}`} index={index} key={`question_${index}`}>
                              {(provided) => (
                                <div ref={provided.innerRef} {...provided.draggableProps}>
                                  <Field name={name} key={name}>
                                    {({ input }) => (
                                      <Flex flexDirection="row" alignItems="center" mb={3}>
                                        <Box
                                          variant="border"
                                          css={css`
                                            border-radius: 4px;
                                          `}
                                          p={2}
                                          pt={1}
                                          mb={2}
                                          backgroundColor="#fff"
                                          width={1}
                                        >
                                          <Field
                                            name={name + '.question'}
                                            component={Input}
                                            as="textarea"
                                            placeholder="Enter Question"
                                            label={`Question ${index + 1} (${
                                              QUESTION_TYPE_NAME[input.value.quizType]
                                            })`}
                                            validate={(value, values) => {
                                              const required = checkRequired(value);

                                              if (required) {
                                                return required;
                                              }

                                              const question = get(values, name);

                                              if (
                                                question?.quizType === QUESTION_TYPE.CHOICE &&
                                                !question.answers?.length
                                              ) {
                                                return 'Multiple choice question must have at least one answer.';
                                              }

                                              if (
                                                question?.quizType === QUESTION_TYPE.BOOL &&
                                                question.answers?.length !== 2
                                              ) {
                                                return 'Boolean question must have two answers exactly.';
                                              }
                                            }}
                                          />
                                          {[
                                            QUESTION_TYPE.LONG_ANSWER,
                                            QUESTION_TYPE.SHORT_ANSWER,
                                            QUESTION_TYPE.GAP,
                                          ].includes(input.value.quizType) && (
                                            <Field
                                              name={name + '.exampleAnswer'}
                                              component={Input}
                                              placeholder="Enter example answer"
                                              tip="Example answer shows as modal in Dandapani mobile application."
                                              label="Example Answer"
                                            />
                                          )}
                                          {(input.value.quizType === QUESTION_TYPE.CHOICE ||
                                            input.value.quizType === QUESTION_TYPE.BOOL) && (
                                            <DroppableAnswers
                                              name={name}
                                              droppableId={`drop_${index}`}
                                              question={input.value}
                                            />
                                          )}
                                        </Box>
                                        <Flex flexDirection="column" ml={2}>
                                          <Box {...provided.dragHandleProps}>
                                            <img
                                              src={require('assets/drag.png')}
                                              css={css`
                                                width: 32px;
                                                height: 24px;
                                                object-fit: contain;
                                              `}
                                              alt="Drag question"
                                            />
                                          </Box>
                                          {[QUESTION_TYPE.CHOICE, QUESTION_TYPE.BOOL].includes(
                                            input.value.quizType
                                          ) && (
                                            <Button
                                              mt={2}
                                              variant="nulled"
                                              onClick={() => {
                                                form.change(name, {
                                                  ...input.value,
                                                  answers: [
                                                    ...input.value.answers,
                                                    {
                                                      answer: '',
                                                      id: String(Math.random() * 10000000),
                                                      quizQuestion: input.value.id,
                                                      isCorrect: false,
                                                    },
                                                  ],
                                                });
                                              }}
                                            >
                                              <img
                                                src={require('assets/add.svg')}
                                                css={css`
                                                  width: 32px;
                                                  height: 32px;
                                                `}
                                                alt="Add new answer"
                                              />
                                            </Button>
                                          )}
                                          <WithModal
                                            text={`Are you sure you want to delete this question? It won't be saved until you press "Save" button.`}
                                            renderButtons={({ close }) => (
                                              <Flex>
                                                <Box width={0.5}>
                                                  <Button variant="outline" onClick={close}>
                                                    Cancel
                                                  </Button>
                                                </Box>
                                                <Box
                                                  width={0.5}
                                                  onClick={() => {
                                                    fields.remove(index);
                                                    close();
                                                  }}
                                                >
                                                  <Button variant="primary">Delete Question</Button>
                                                </Box>
                                              </Flex>
                                            )}
                                          >
                                            {({ open }) => (
                                              <Button variant="nulled" mt={2} onClick={open}>
                                                <img
                                                  src={require('assets/delete.svg')}
                                                  css={css`
                                                    width: 32px;
                                                    height: 20px;
                                                  `}
                                                  alt="Delete question"
                                                />
                                              </Button>
                                            )}
                                          </WithModal>
                                        </Flex>
                                      </Flex>
                                    )}
                                  </Field>
                                </div>
                              )}
                            </Draggable>
                          ))}

                          <Field name="questionType">
                            {({ input, meta }) => (
                              <Flex flexDirection="row" mt={3}>
                                <Flex width={130} alignItems="center">
                                  <TippedLabel>Add Question:</TippedLabel>
                                </Flex>
                                <Flex flex={0.6}>
                                  <Box width={1}>
                                    <Select input={input} meta={meta}>
                                      {type === QUIZ_TYPE.QUIZ ? (
                                        <>
                                          <option value={QUESTION_TYPE.CHOICE}>Multiple Choice</option>
                                          <option value={QUESTION_TYPE.BOOL}>True or False</option>
                                        </>
                                      ) : (
                                        <>
                                          <option value={QUESTION_TYPE.GAP}>Fill a Gap</option>
                                          <option value={QUESTION_TYPE.SHORT_ANSWER}>Short Answer</option>
                                          <option value={QUESTION_TYPE.LONG_ANSWER}>Long Answer</option>
                                        </>
                                      )}
                                    </Select>
                                  </Box>
                                </Flex>
                                <Flex ml={3}>
                                  <Button
                                    variant="nulled"
                                    onClick={() => {
                                      const quizType = Number(input.value);
                                      const id = String(Math.random() * 10000000);
                                      const answers =
                                        quizType === QUESTION_TYPE.BOOL
                                          ? [
                                              {
                                                answer: 'True',
                                                id: String(Math.random() * 10000000),
                                                quizQuestion: id,
                                                isCorrect: true,
                                              },
                                              {
                                                answer: 'False',
                                                id: String(Math.random() * 10000000),
                                                quizQuestion: id,
                                                isCorrect: false,
                                              },
                                            ]
                                          : [
                                              {
                                                answer: '',
                                                id: String(Math.random() * 10000000),
                                                quizQuestion: id,
                                                isCorrect: true,
                                              },
                                            ];

                                      fields.push({
                                        quizType,
                                        id,
                                        answers: [QUESTION_TYPE.BOOL, QUESTION_TYPE.CHOICE].includes(quizType)
                                          ? answers
                                          : [],
                                        question: '',
                                        quiz: quiz.id,
                                        status: QUIZ_STATUS.DRAFT,
                                      });
                                    }}
                                  >
                                    <img
                                      src={require('assets/add.svg')}
                                      css={css`
                                        width: 40px;
                                        height: 40px;
                                      `}
                                      alt="Add new row"
                                    />
                                  </Button>
                                </Flex>
                              </Flex>
                            )}
                          </Field>
                          {provided.placeholder}
                        </div>
                      )}
                    </Droppable>
                    <Button primary loading={meta.submitting} type="submit" mt={3}>
                      Save
                    </Button>
                  </DragDropContext>
                )}
              </FieldArray>
            )}
          </Box>
        </Box>
      )}
    </Form>
  );
};

export default React.memo(DisplayQuiz);
